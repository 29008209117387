<template>
    <div class="btn none-selectable" :class="{active: active}">
        <p v-html="title"></p>
    </div>
</template>

<script>
export default {
  name: 'Btn',
  props:{
      title: String,
      active: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.btn{
    cursor:pointer;
    display: flex;  
    height: 62px;
    margin-bottom: 5px;
    padding: 0px 12px;
    background: #928B85;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    text-decoration:none;
    color: #FFFFFF;
}
.btn p{
    margin:auto;
    align-self:center;
}
.active{
    background: #F26321;
}
</style>