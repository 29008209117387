<template>
  <div class="tablebox">
    <TableTitle :info='data[0]'/>
    <TableRow
      v-for="(drug,index) in data"
      v-if="index>0"
      :index="index"
      :key="drug.id"
      :druginfo="drug"
    />
    <div class="table-remark" v-if="flRemark">{{remText}}</div>  
    <div class="table-remark" v-if="flAreaRem" v-html="areaRemText"></div>  
  </div>
</template>

<script>
import TableTitle from '@/components/table/TableTitle.vue'
import TableRow from '@/components/table/TableRow.vue'

export default {
  name: 'Table',
  components:{
    TableRow,
    TableTitle
  },
  props:{
    data: Array,
    width: Number,
    flRemark: Boolean,
    remText: String,
    areaRemText: String,
    flAreaRem: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tablebox {
  background-color:rgb(255 255 255 / 40%);
  padding: 10px 20px;
}
.table-remark {
  color: #787572;
  font-weight: 300;
  font-size: 13px;
  margin-top: 8px;
  margin-left: 14px;
}
</style>