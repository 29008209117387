<template>
  <div class="graphbox" :style="{height: twoBars ? '383px' : '345px' }">
    <div class="strokes">
      <div v-for = "n in 5" class="stroke">
      </div>
    </div>

    <div class="y-axis">
      <p class="axis_title">млн.<br>руб</p>
      <div class="marks">
        <p v-for="(capt, index) in graphset.marks.slice().reverse()"
        class="capt"
        :index="index"
        :class="{'first': index===0, 'last': index===4}"
        :key="capt.id">{{ capt }}</p>
      </div>
    </div>

    <div class="graph">
      <div class="bar" v-if="twoBars || flag">
        <!--<p class="total_caption">{{ summRound(data.optim) }}</p>-->
        <div v-for="(part, index) in sorted(data.optim)"
          class="drugbar"  :class="twoBars ? '' : 'singleBar'"
          :style="{height:(part.cost*graphset.coef+'px'), 
                   background: flag ? part.drug.color : part.drug.gcolor}"
        >
        <p v-if="part.cost*graphset.coef > 15" class="bar_caption" :style="{margin: ((part.cost*graphset.coef/2)-10)+'px' + ' 0 0 0'}">{{ mlnRound(part.cost) }}</p>
          <svg v-else width="50px" height="100px">
            <line x1="0" y1="23px" x2="10px" :y2='18+10*index*index' stroke-width="1" stroke="#787572"></line>
            <line x1="10" :y1='18+10*index*index' x2="25px" :y2='18+10*index*index' stroke-width="1" stroke="#787572"></line>
            <text x="10px" :y='index == 0 ? 15 : 10+30*index' font-size="13px" fill="#787572">{{ mlnRound(part.cost) }}</text>
          </svg>
        </div>
      </div>
      <div class="bar" v-if="twoBars || !flag" :class="twoBars ? '' : 'singleBar'">
        <!--<p class="total_caption">{{ summRound(data.origin) }}</p>-->
        <div v-for="(part, index) in sorted(data.origin)"
          class="drugbar"  
          :style="{height:(part.cost*graphset.coef+'px'), 
                   background: flag ? part.drug.gcolor : part.drug.color,
                   display: part.cost === 0 ? 'none': 'flex'}"
        >
          <p v-if="part.cost*graphset.coef > 15" class="bar_caption" :style="{margin: ((part.cost*graphset.coef/2)-10)+'px' + ' 0 0 0'}">{{ mlnRound(part.cost) }}</p>
          <svg v-else width="50px" height="100px">
            <line x1="0" y1="23px" x2="10px" :y2='18+10*index*index' stroke-width="1" stroke="#787572"></line>
            <line x1="10" :y1='18+10*index' x2="25px" :y2='18+10*index*index' stroke-width="1" stroke="#787572"></line>
            <text x="10px" :y='index == 0 ? 15 : 10+30*index' font-size="13px" fill="#787572">{{ mlnRound(part.cost) }}</text>
          </svg>
        </div>
      </div>
    </div>

    <div v-if="twoBars" class="subtitles">
      <div class="bar_subtitle"> При участии<br>в программе </div>
      <div class="bar_subtitle"> Без участия<br>в программе </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'HorisontalGraph',
  components:{
  },
  props:{
    data: Object,
    flag: Boolean,
    twoBars: Boolean
  },
  methods:{
    sorted(parts){
      parts = parts.filter(function(f) { return f.cost !== 0 })
      return parts.sort(function(part1, part2){return part1.cost - part2.cost});
    },
    getGrayScale(hex){
      let c = (hex.replace('#','')).split('');
      let r = parseInt((c[0] + c[0]), 16);
      let g = parseInt((c[1] + c[1]), 16);
      let b = parseInt((c[2] + c[2]), 16);
      let gray = Math.floor((r+g+b)/2);
      let str = 'rgb(' + gray +' ' + gray + ' ' + gray + ' / 50%)';
      return str;
    },
    mlnRound(num){
      let number = Math.ceil(num / 100000)/10;
      return number.toFixed(1.1).replace('.',',');
    },
    summRound(data){
      let number = Math.ceil(data.reduce(function(sum, el) {
	        return sum + el.cost;
        }, 0) / 100000)/10;
      return number.toFixed(1.1).replace('.',',');
    }
  },
  computed:{
    graphset:function(){
      //размер одной секции на графике
      let summ=0;
      for(var i = 0; i < this.data.origin.length; i++){
        summ += this.data.origin[i].cost;
      }
      let sect_value=(Math.ceil(summ/4/500000)/2); //4 отрезка для шкалы, округление на шкале кратно 0,5 млн

      //массив набор подписей для графика
      let arr=[];
      arr.push(0);
      for(var i = 1; i < 5; i++){
        arr.push((arr[i-1]+sect_value));
      }
      return {
             sect: sect_value,
             marks: arr,
             coef: 63/(sect_value*1000000) //171px длина одного отрезка на оХ
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.graphbox{
  background-color:rgb(255 255 255 / 40%);
  margin-left:10px;
  margin-top:6px;
  padding:10px 20px;
  box-sizing:border-box;
  width: 260px;
  /*height: 345px;*/
}
.graph{
  display:inline-flex;
  border: solid #757575;
  border-width: 0px 0px 1px 1px;
  height: 304px;
  margin: 10px 0 0px 10px;
}
.strokes{
  margin-left: 28px;
  position: absolute;
  display: flex;
  width: 195px;
  flex-wrap: wrap;
  align-items: flex-end;
  height: 315px;
}
.stroke{
  height: 60px;
  border-bottom: 1px solid rgb(117 117 117 / 30%);
  width: 190px;
  flex-shrink:0;
}
.bar{
  margin: 0px 20px 0px 18px;
  position:relative;
  display:inline-block;  
  z-index:100;
  width:50px;
  align-self: flex-end;
}
.bar_caption{
  margin: auto;
  text-align: center;
  padding: 0;
  color: #fff;
  position: absolute;
  width: 50px;
  font-size: 14px;
}
.singleBar .bar_caption{
  width:58px;
}
.total_caption{
  position:absolute;
  width: 58px;
  text-align: center;
  padding: 0;
  margin-top: -24px;
  font-size: 14px;
  font-weight:600;
}
.singleBar {
  margin: 0px 0px 0px 62px;
  width:58px;
}
.drugbar{
  width:100%;
  min-height: 3px;
  display:flex;
}
.y-axis{
  display:inline-block;
}
.marks{
  display:inline-block;
  margin-left: -4px;
}
.capt{
  color:#787572;
  width: 30px;
  text-align: center;
  margin-bottom: 44px;
}
.capt.first{
  margin-top: 6px;
}
.capt.last{
  margin: -7px 0;
}
.axis_title{
  color:#787572;  
  margin: 0;
  text-align: center;
  font-size: 14px;
  padding-bottom: 7px;
}
.subtitles{
  margin-left:33px;
}
.bar_subtitle{
  display: inline-block;
  text-align: center;
  font-size: 13px;
  margin: 9px 7px;
  color: #787572;
}
svg{
  position:absolute;
  margin-left: 50px;
  margin-top: -21px;
}
.singleBar svg{
  margin-left:57px;
}
</style>