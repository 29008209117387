<template>
  <div class="row" >
    <div class="drugcol" :style="{ width: calcWidth }">
      <p>
        <span class="drugname" :style="{color:druginfo[0].color}">{{druginfo[0].name}}</span><br>
        <span class="drugdose" v-html="druginfo[0].dose">druginfo[0].dose</span>
      </p>
    </div>
    <div class="col"
      :style="{ width: calcWidth}" 
      v-for="(col, index) in druginfo"
      v-if="index>0"
      :key="col.id"
      :index="index">
      <p>{{ col }}</p>
    </div>
  </div>
</template>

<script>


export default {
  name: 'TableRow',
  props:{
    druginfo: Array
  },
  computed:{
    calcWidth:function(){
      return 100/this.druginfo.length+'%' ;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row{
  display:flex;
  border-bottom: 1px solid #787572;
  padding-left: 12px;
}
.col{
  display:flex;
  align-items: center;
  justify-content: center;
  color:#787572;
}
.drugcol{
  display:flex;
  align-items: center;
  }
.drugcol p{
  margin: 7px 0;
}
.drugname{
  font-weight: 600;
  color:#787572;
  margin: 5px 0px;
}
.drugdose{
  margin: 5px 0px;
  color:#787572;
}
</style>