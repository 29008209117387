<template>
    <div>
        <p><font-awesome-icon icon="question-circle" 
            @focus="flOver = true" @focusout="flOver = false" 
            @mouseover="flOver = true" @mouseleave="flOver = false"/>
        </p>
        <div v-if="vflag" class="msg">
			     {{noteText}}
		    </div>
    </div>
</template>

<script>
import dataStore from '@/views/data';

export default {
  name: 'CheckBox',
  props:{
    remOver: Boolean
  },
  data:function(){
      return {
        flOver: false
      }
  },

  computed: {

    vflag() {

      let vf = false;

      if(this.flOver || this.remOver) vf = true;
      return vf;
    },

    noteText() {

      let txt = '', indic = dataStore.currIndic;

      if(indic<2) txt = 'Компания Бристол-Майерс Сквибб (БМС) в рамках договора целевой передачи лекарственного препарата, заключенного между БМС и ЛПУ, безвозмездно предоставляет ЛПУ упаковки лекарственного препарата Ипилимумаб для проведения 3-й и 4-й инфузии комбинацией препаратов Ипилимумаб и Ниволумаб при условии, что пациент получил и хорошо перенес первые 2 инфузии, и в соовтетствии с условиями договора.';
      if(indic==4 || indic==5) txt = 'Компания Бристол-Майерс Сквибб (БМС), в рамках договора целевой передачи лекарственного препарата, заключенного между БМС и ЛПУ, безвозмездно предоставляет ЛПУ упаковки лекарственного препарата Ипилимумаб, в количестве которое было израсходовано на лечение пациентов с НМРЛ, сошедших с терапии в течении 24 недель с момента начала лечения в соовтетствии с условиями договора.';
      return txt;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p svg{
     outline: none !important;
}
p{
    cursor:pointer; 
    margin-top: 0;
    color:#787572;
}
.msg{
    position: absolute;
    margin-top: -4px;
    margin-left: -550px;
    width: 780px;
    text-align: justify;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #c8dee6;
    color: #787572;
    font-size: 13px;
    box-shadow: 2px 3px 10px #928b856b;
}
</style>